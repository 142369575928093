import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getConfigValue, getConfigCollection } from '../util/getConfigValue';
import { AppContext } from '../util/AppContext';
import { getSanitizedHtml } from '../util/getSanitizedHtml';
import PlanListRow from './PlanListRow';
import Mustache from 'mustache';

PlanListTable.propTypes = {
	updateSelectedPlans: PropTypes.func,
	message: PropTypes.object,
	parentState: PropTypes.shape({
		isLoading: PropTypes.bool,
		isSingleSelection: PropTypes.bool,
		savingsCardIsChecked: PropTypes.bool,
		selectionMessage: PropTypes.string,
		exceedMaxColumns: PropTypes.bool,
		maxDisplayColumns: PropTypes.number,
		isImprovedAccessStatic: PropTypes.bool,
		selectableOptions: PropTypes.shape({
			canSelectFormulary: PropTypes.bool,
			canSelectRestrictions: PropTypes.bool,
			canSelectSpecialtyPharmacy: PropTypes.bool,
			canSelectSavingsCard: PropTypes.bool,
		}),
		isSingleOptionSelectionEnabled: PropTypes.bool,
		isSingleOptionFormularyDrillDownEnabled: PropTypes.bool,
		isSingleOptionRestrictionDetailsEnabled: PropTypes.bool,
		isSingleOptionSpecialtyPharmacyEnabled: PropTypes.bool,
		isSingleOptionSavingsCardEnabled: PropTypes.bool,
		selectedPlans: PropTypes.array,
		preSelectedPlans: PropTypes.array
	}),
	setSelectableOptions: PropTypes.func,
	triggerToastAlert: PropTypes.func,
	executePrintOptionRestrictions: PropTypes.func,
	showTooManyOptionsSelectedMessage: PropTypes.func,
};

function PlanListTable({
	updateSelectedPlans,
	message,
	parentState,
	setSelectableOptions,
	triggerToastAlert,
	executePrintOptionRestrictions,
	showTooManyOptionsSelectedMessage,
}) {
	const [appState] = useContext(AppContext);
	const [plans, setPlans] = useState(message.dynamicTable);
	const [isExpandAll, setIsExpandAll] = useState(false);
	const [toggleCollapse, setToggleCollapse] = useState(false);
	const [selectedPlans, setSelectedPlans] = useState([]);

	const formularyDrillDownEnabled = getConfigValue(appState.config.Formulary?.DrillDown?.Enabled, appState)?.Value === 'true';
	const restrictionDrillDownEnabled = getConfigValue(appState.config.Restriction?.DrillDown?.Enabled, appState)?.Value === 'true';
	const specialtyPharmacyDrillDownEnabled = getConfigValue(appState.config.SpecialtyPharmacy?.DrillDown?.Enabled, appState)?.Value === 'true';
	const planListConfig = useMemo(() => getConfigCollection(appState.config?.PlanList, appState, 'PlanList'), [appState]);

	const maxPlans = getConfigValue(appState.config.PlanList.MaxPrintablePlans, appState)?.Value || 6;

	useEffect(() => {
		updateSelectedPlans(message.prescriberMessage.messageCode, selectedPlans);
	}, [selectedPlans, message]);

	useEffect(() => {
		if(parentState.preSelectedPlans && parentState.preSelectedPlans.length > 0){
			setSelectedPlans(parentState.preSelectedPlans);
		}
	}, [parentState.preSelectedPlans]);

	useEffect(() => {
		// force a reset of the single option statuses when a plan is deselected
		executePrintOptionRestrictions({}, parentState, setSelectableOptions, showTooManyOptionsSelectedMessage);
	}, [parentState.selectedPlans]);

	useEffect(() => {
		setPlans(message.dynamicTable);
		setSelectedPlans([]);
	}, [message]);

	function updateCollapseExpand(expand) {
		setIsExpandAll(expand);
		setToggleCollapse(!toggleCollapse);
	}

	function handleCheckboxClick(plan, event) {
		const isChecked = event.target.checked;
		const maxReached = selectedPlans.length >= maxPlans;

		if (parentState.isLoading) {
			event.preventDefault();
			return;
		}
		if (parentState.isSingleSelection && isChecked) {
			setSelectedPlans([plan]);
			return;
		}

		if (!isChecked) {
			setSelectedPlans((originalPlans) => originalPlans.filter((p) => p.planId !== plan.planId));
		}

		if (isChecked && !maxReached) {
			setSelectedPlans((originalPlans) => [...originalPlans, plan]);
		}

		if (isChecked && maxReached) {
			event.preventDefault();
			showMaxPlansMessage();
		}		
	}

	function onOptionsClick(data) {
		if (!executePrintOptionRestrictions(data, parentState, setSelectableOptions, showTooManyOptionsSelectedMessage)) {
			return false;
		}

		const toInclude = data.includeFormularies || data.includeRestrictions || data.includeSpecialtyPharmacy;

		// handle single selection, if something is checked, replaces the one selected plan
		if (parentState.isSingleSelection) {
			if (toInclude) {
				setSelectedPlans([data]);
			}
			return true;
		}

		// handle multiple selection
		const plan = selectedPlans.find((p) => p.planId === data.planId);
		if (!plan && toInclude) {
			// if max plans reached, don't allow adding more
			if (selectedPlans.length >= maxPlans) {
				showMaxPlansMessage();
				return false;
			}
			if (!checkMaxFormularyPlans(data) || !checkMaxRestrictionPlans(data) || !checkMaxSpecialtyPlans(data)) {
				return false;
			}
			setSelectedPlans((originalPlans) => [...originalPlans, data]);
			return true;
		}
		if (toInclude && (!checkMaxFormularyPlans(data) || !checkMaxRestrictionPlans(data) || !checkMaxSpecialtyPlans(data))) {
			return false;
		}
		setSelectedPlans((originalPlans) => [...originalPlans.filter((p) => p.planId !== plan.planId), data]);
		return true;
	}

	function showMaxPlansMessage() {
		const errorMessage = getConfigValue(appState.config?.PlanList?.UserMessages?.MaxPrintablePlans?.Body, appState)?.Value;
		triggerToastAlert(
			getConfigValue(appState.config?.PlanList?.UserMessages?.MaxPrintablePlans?.Title, appState)?.Value,
			Mustache.render(errorMessage, planListConfig),
			false
		);
	}

	// check if max number of restriction notes
	function checkMaxRestrictionPlans(plan) {
		if (!plan.includeRestrictions) {
			return true;
		}
		const maxRestrictionPlans = getConfigValue(appState.config?.PlanList?.MaxRestrictionPlans, appState)?.Value;
		if (!maxRestrictionPlans) {
			return true;
		}
		const selectedRestrictionPlans = selectedPlans.filter((p) => p.includeRestrictions);
		if (selectedRestrictionPlans.length >= parseInt(maxRestrictionPlans)) {
			const errorMessage = getConfigValue(appState.config?.PlanList?.UserMessages?.MaxRestrictionPlans?.Body, appState)?.Value;
			triggerToastAlert(
				getConfigValue(appState.config?.PlanList.UserMessages?.MaxRestrictionPlans?.Title, appState)?.Value,
				Mustache.render(errorMessage, planListConfig),
				false
			);
			return false;
		}
		return true;
	}

	// check if the plan is marked to include formularies and if the max number of formularies has been reached (in case there is a max)
	function checkMaxFormularyPlans(plan) {
		if (!plan.includeFormularies) {
			return true;
		}
		const maxFormularyPlans = getConfigValue(appState.config?.PlanList?.MaxFormularyPlans, appState)?.Value;
		if (!maxFormularyPlans) {
			return true;
		}
		const selectedFormularyPlans = selectedPlans.filter((p) => p.includeFormularies);
		if (selectedFormularyPlans.length >= parseInt(maxFormularyPlans)) {
			const errorMessage = getConfigValue(appState.config?.PlanList?.UserMessages?.MaxFormularyPlans?.Body, appState)?.Value;
			triggerToastAlert(
				getConfigValue(appState.config?.PlanList?.UserMessages?.MaxFormularyPlans?.Title, appState)?.Value,
				Mustache.render(errorMessage, planListConfig),
				false
			);
			return false;
		}
		return true;
	}

	// check if the plan is marked to include specialty and if the max number of specialty has been reach (in case there is a max)
	function checkMaxSpecialtyPlans(plan) {
		if (!plan.includeSpecialtyPharmacy) {
			return true;
		}
		const maxSpecialtyPharmaciesPlans = getConfigValue(appState.config?.PlanList?.MaxSpecialtyPharmacyPlans, appState)?.Value;
		if (!maxSpecialtyPharmaciesPlans) {
			return true;
		}
		const selectedSpecialtyPharmaciesPlans = selectedPlans.filter((p) => p.includeSpecialtyPharmacy);
		if (selectedSpecialtyPharmaciesPlans.length >= parseInt(maxSpecialtyPharmaciesPlans)) {
			const errorMessage = getConfigValue(appState.config?.PlanList?.UserMessages?.MaxSpecialtyPharmaciesPlans?.Body, appState)?.Value;
			triggerToastAlert(
				getConfigValue(appState.config?.PlanList?.UserMessages?.MaxSpecialtyPharmaciesPlans?.Title, appState)?.Value,
				Mustache.render(errorMessage, planListConfig),
				false
			);
			return false;
		}
		return true;
	}

	return (
		<>
			{/* <!-- Instructions row. May depend on message type --> */}
			<div className='row my-5 my-lg-4 align-items-center justify-content-lg-between'>
				<div className='col-12 col-md-6'>
					<p className='m-0'>{parentState.selectionMessage}</p>
				</div>
				<div className='col-12 col-md-6 d-flex align-middle justify-content-md-end mt-3 mt-md-0'>
					<div className='d-flex flex-row'>
						<button
							onClick={() => updateCollapseExpand(true)}
							disabled={!parentState.exceedMaxColumns}
							className='btn btn-ghost-primary btn-icon btn-sm type-14 me-3'
						>
							{getConfigValue(appState.config?.PlanList?.ExpandAll?.Label, appState)?.Value || null}{' '}
							<span className='fa-regular fa-circle-plus btn-icon'></span>
						</button>
						<button
							onClick={() => updateCollapseExpand(false)}
							disabled={!parentState.exceedMaxColumns}
							className='btn btn-ghost-primary btn-icon btn-sm type-14'
						>
							{getConfigValue(appState.config?.PlanList?.CollapseAll?.Label, appState)?.Value || null}{' '}
							<span className='fa-regular fa-circle-minus btn-icon'></span>
						</button>
					</div>
				</div>
			</div>
			{/* <!-- Plan list --> */}
			<div className='container-xl'>
				{/* <!-- Plan table header row --> */}

				<div className='row pd-plan-table-header align-items-lg-center d-none d-lg-flex py-3'>
					<div className='pd-plan-col col-lg-1'></div>

					<div className='pd-plan-col col-lg-9'>
						<div className='row align-items-lg-center'>
							{plans?.headers?.map((header, index) => {
								return (
									index < parentState.maxDisplayColumns && (
										<div key={header.key} className='col-lg' dangerouslySetInnerHTML={getSanitizedHtml(header.title)}></div>
									)
								);
							})}
						</div>
					</div>

					{/* <!-- Button - options --> */}

					<div className='pd-plan-col pd-plan-button col-lg-1'></div>
					{/* <!-- Button - more info --> */}

					<div className='pd-plan-col col-lg-1'></div>
				</div>

				{plans?.rows?.map((row, index) => {
					return (
						<PlanListRow
							plan={row}
							key={row.data.dimPlanIdentity}
							onClick={handleCheckboxClick}
							index={index}
							onOptionsClick={onOptionsClick}
							isSingleSelection={parentState.isSingleSelection}
							isImprovedAccessStatic={parentState.isImprovedAccessStatic}
							isExpandAll={isExpandAll}
							selectedPlans={selectedPlans}
							tableColumnHeaders={plans.headers}
							exceedMaxColumns={parentState.exceedMaxColumns}
							toggleCollapse={toggleCollapse}
							formularyDrillDownEnabled={formularyDrillDownEnabled}
							restrictionDrillDownEnabled={restrictionDrillDownEnabled}
							specialtyPharmacyDrillDownEnabled={specialtyPharmacyDrillDownEnabled}
							canSelectFormulary={parentState.selectableOptions.canSelectFormulary}
							canSelectRestrictions={parentState.selectableOptions.canSelectRestrictions}
							canSelectSpecialtyPharmacy={parentState.selectableOptions.canSelectSpecialtyPharmacy}
						/>
					);
				})}
			</div>
		</>
	);
}

export default PlanListTable;
