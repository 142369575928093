import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryDropdown from '../components/CategoryDropdown';
import MessageDetailsModal from '../components/MessageDetailsModal';
import PreviewModal from '../components/PreviewModal';
import { getMessages } from '../API/message';
import getName from '../util/getName';
import { AppContext } from '../util/AppContext';
import PrintOptionDropdown from '../components/printOptions/printOptionDropdown';
import { documentGenerate, documentGenerationType } from '../API/documentGenerate';
import ToastAlert from '../components/ToastAlert';
import { getConfigValue, getConfigCollection } from '../util/getConfigValue';
import Mustache from 'mustache';
import { ProductsModal, ProductsModalButton } from '../components/ProductsModal';
import MessageTypes from '../util/messageTypes';
import { useErrorBoundary } from 'react-error-boundary';
import useScrollPosition from '../hooks/useScrollPosition';
import { hcpType } from '../util/constants';
import PlanListTable from '../components/PlanListTable';
import executePrintOptionRestrictions from '../util/printOptionRestrictions';

function PlanListPage() {
	const [appState, setAppState] = useContext(AppContext);
	const { state } = useLocation();
	const { showBoundary } = useErrorBoundary();

	const [showProductsModal, setShowProductsModal] = useState(false);
	const [currentProduct, setCurrentProduct] = useState(appState?.currentProduct);
	const [productImageSource, setProductImageSource] = useState(appState?.currentProduct?.imagePath);
	const [prescriberId] = useState(appState.prescriber?.id);
	const [messageCode, setMessageCode] = useState(appState.category?.messageCode);
	const [categories, setCategories] = useState([]);
	const [pageCategory, setPageCategory] = useState(appState.category);
	const [mainMessage, setMainMessage] = useState(appState.mainMessage);
	const [plans, setPlans] = useState(appState.plans);
	const [territoryId] = useState(appState.territory?.id);
	const [marketId] = useState(appState.market?.id);

	const tier = state?.tier || null;
	const planName = state?.planName || null;
	let planId = state?.planId || null;
	const prescriberType = appState?.prescriberType;

	const [isImprovedAccessStatic, setIsImprovedAccessStatic] = useState(false);
	const [currentMessage, setCurrentMessage] = useState(null);
	const [currentPrescriberMessage, setCurrentPrescriberMessage] = useState(null);
	const [minPlans, setMinPlans] = useState(null);
	const [maxPlans, setMaxPlans] = useState(null);

	const [selectedPlans, setSelectedPlans] = useState([]);
	const [preSelectedPlans, setPreSelectedPlans] = useState([]);

	const [showToast, setShowToast] = useState(false);
	const [toastTitle, setToastTitle] = useState('');
	const [toastMessage, setToastMessage] = useState('');
	const [toastAutohide, setToastAutohide] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const [showMessageDetailsModal, setShowMessageDetailsModal] = useState(false);
	const [showPreviewModal, setShowPreviewModal] = useState(false);

	const [isSingleSelection, setIsSingleSelection] = useState(false);
	const [selectionMessage, setSelectionMessage] = useState('Loading...');

	const [isConfigMenuVisible, setIsConfigMenuVisible] = useState(false);
	const [configHCPisChecked, setConfigHCPisChecked] = useState(getConfigValue(appState.config?.PrintOptions?.HCPName?.Default, appState)?.Value === 'true');
	const [configEHRisChecked, setConfigEHRisChecked] = useState(
		getConfigValue(appState.config?.PrintOptions?.EhrBackPage?.Default, appState)?.Value === 'true'
	);

	const [selectedColumnState, setSelectedColumnState] = useState([]);
	const [maxDisplayColumns, setMaxDisplayColumns] = useState(5);
	const minPrintColumns = getConfigValue(appState.config.PlanList.MinPrintableColumns, appState)?.Value || 3;

	const [pdfUrl, setPdfUrl] = useState('');
	const [exceedMaxColumns, setExceedMaxColumns] = useState(false);
	const [pdfFileName, setPdfFileName] = useState('');

	const configMenuRef = useRef(null);
	const configMenuContentRef = useRef(null);

	const navigate = useNavigate();

	const scrollPosition = useScrollPosition();
	const planListConfig = useMemo(() => getConfigCollection(appState.config?.PlanList, appState, 'PlanList'), [appState]);
	const printOptionsEhrBackPageEnabled = getConfigValue(appState.config.PrintOptions?.EhrBackPage?.Enabled, appState)?.Value === 'true';
	const printOptionsCoverPageEnabled = getConfigValue(appState.config?.PrintOptions?.HCP?.CoverPage?.Enabled, appState)?.Value === 'true';
	const [printOptionsCoverPageIsChecked, setPrintOptionsCoverPageIsChecked] = useState(
		getConfigValue(appState.config?.PrintOptions?.HCP?.CoverPage?.Default, appState)?.Value === 'true'
	);
	const [configLocationNameIsChecked, setConfigLocationNameIsChecked] = useState(
		getConfigValue(appState.config.PrintOptions?.TurnOffLocationName?.Default, appState)?.Value === 'true'
	);
	const [savingsCardIsChecked, setSavingsCardIsChecked] = useState(
		getConfigValue(appState.config?.PrintOptions?.SavingsCard?.Default, appState)?.Value === 'true'
	);
	const savingsCardIsEnabled = getConfigValue(appState.config?.PrintOptions?.SavingsCard?.Enabled, appState)?.Value === 'true';

	const isSingleOptionSelectionEnabled =
		getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.Enabled, appState)?.Value === 'true';
	const isSingleOptionFormularyDrillDownEnabled =
		getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.FormularyDrillDown?.Selectable, appState)?.Value === 'true';
	const isSingleOptionSavingsCardEnabled =
		getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.SavingsCard?.Selectable, appState)?.Value === 'true';
	const isSingleOptionRestrictionDetailsEnabled =
		getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.RestrictionDetails?.Selectable, appState)?.Value === 'true';
	const isSingleOptionSpecialtyPharmacyEnabled =
		getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.SpecialtyPharmacy?.Selectable, appState)?.Value === 'true';
	const [selectableOptions, setSelectableOptions] = useState({});

	const parentState = {
		isLoading: isLoading,
		isSingleSelection: isSingleSelection,
		savingsCardIsChecked: savingsCardIsChecked,
		selectionMessage: selectionMessage,
		exceedMaxColumns: exceedMaxColumns,
		maxDisplayColumns: maxDisplayColumns,
		isImprovedAccessStatic: isImprovedAccessStatic,
		selectableOptions: selectableOptions,
		isSingleOptionSelectionEnabled: isSingleOptionSelectionEnabled,
		isSingleOptionFormularyDrillDownEnabled: isSingleOptionFormularyDrillDownEnabled,
		isSingleOptionRestrictionDetailsEnabled: isSingleOptionRestrictionDetailsEnabled,
		isSingleOptionSpecialtyPharmacyEnabled: isSingleOptionSpecialtyPharmacyEnabled,
		isSingleOptionSavingsCardEnabled: isSingleOptionSavingsCardEnabled,
		selectedPlans: selectedPlans,
		preSelectedPlans: preSelectedPlans
	};

	function isPageValid() {
		//check min selected columns
		if (selectedColumnState.length < minPrintColumns) {
			return false;
		}

		if (selectedPlans.length < minPlans || selectedPlans.length > maxPlans) {
			return false;
		}
		//check soft restrictions for each message plan
		for (let plan of selectedPlans) {
			if (
				plan.minPrintPlans &&
				parseInt(plan.minPrintPlans) > 0 &&
				selectedPlans.filter((e) => e.planClass !== plan.planClass).length < parseInt(plan.minPrintPlans)
			) {
				return false;
			}
		}
		return true;
	}
	const isValid = isPageValid();

	useEffect(() => {
		if (categories === null || categories.length === 0) {
			getMessageCategories();
		}

		updateSelectMessageCategory(pageCategory);

		// set the initial controls
		setSelectableOptions({
			canSelectFormulary: true,
			canSelectRestrictions: true,
			canSelectSpecialtyPharmacy: true,
			canSelectSavingsCard: true
		});

		executePrintOptionRestrictions({}, parentState, setSelectableOptions, showTooManyOptionsSelectedMessage);
	}, []);

	useEffect(() => {
		if (appState?.config?.PlanList?.MaxDisplayColumns) {
			setMaxDisplayColumns(parseInt(appState.config.PlanList.MaxDisplayColumns.Value));
		}
	}, [appState]);

	useEffect(() => {
		if (currentPrescriberMessage) {
			if ((minPlans === 1 && maxPlans === 1) || isSingleSelection) {
				setIsSingleSelection(true);
				setSelectionMessage(getConfigValue(appState.config.PlanList.SingleSelectionMessage, appState)?.Value);
			} else {
				let message = getConfigValue(appState.config.PlanList.MinMaxPrintMessage, appState)?.Value;
				setSelectionMessage(Mustache.render(message, planListConfig));
			}
		}
	}, [currentPrescriberMessage, minPlans, maxPlans]);

	useEffect(() => {
		if (messageCode && prescriberId && !pageCategory.isContainerMessage) {
			loadMessages();
		}
		// eslint-disable-next-line
	}, [messageCode, prescriberId, appState?.config, pageCategory]);

	useEffect(() => {
		if (planId && plans && isImprovedAccessStatic && selectedPlans.length === 0) {			
			const preSelected = [plans.rows.find((p) => p.data.planId === planId).data];
			setSelectedPlans(preSelected);
			setPreSelectedPlans(preSelected);
		}
	}, [planId, plans, isImprovedAccessStatic, selectedPlans]);

	function planListHeaderSticky() {
		const planListHeader = document.getElementById('plan-list-header');
		if (planListHeader) {
			if (scrollPosition > 150) {
				planListHeader.classList.add('plan-list-header-sticky');
				planListHeader.classList.add('border-bottom');
				document.getElementById('plan-list-header-spacer').classList.add('d-block');
				document.getElementById('plan-list-header-spacer').classList.remove('d-none');
			} else if (planListHeader.classList.contains('plan-list-header-sticky')) {
				planListHeader.classList.remove('plan-list-header-sticky');
				planListHeader.classList.remove('border-bottom');
				document.getElementById('plan-list-header-spacer').classList.remove('d-block');
				document.getElementById('plan-list-header-spacer').classList.add('d-none');
			}
		}
	}

	function updateSelectMessageCategory(pageCategory) {
		setMessageCode(pageCategory.messageCode);
		setAppState({
			...appState,
			category: pageCategory,
			messageCode: pageCategory.messageCode,
		});

		const isImprovedAccess = pageCategory.messageType === MessageTypes.ImprovedAccess || false;
		setIsImprovedAccessStatic(isImprovedAccess);
		const isSingleWinPlan = pageCategory.messageType === MessageTypes.SingleWinPlan || false;
		setIsSingleSelection(isSingleWinPlan);
	}

	function triggerToastAlert(title, message, autohide) {
		setToastTitle(title);
		setToastMessage(message);
		setShowToast(true);
		setToastAutohide(autohide);
	}

	function loadMessages() {
		getMessages(messageCode, prescriberId, appState.currentProduct.id, appState.category.messageTypeId)
			.then((responseJson) => {
				const prescriberMessage = responseJson.prescriberMessage;
				const responsePlans = responseJson.dynamicTable || [];
				const responseMainMessage = responseJson.prescriberMessage.mainMessage || '';
				const minPlans = getConfigValue(appState.config.PlanList.MinPrintablePlans, appState)?.Value || null;
				const maxPrintPlans = getConfigValue(appState.config.PlanList.MaxPrintablePlans, appState)?.Value || 6;
				if (planId && prescriberMessage.category === 'Improved Access') {
					const selectedPlans = [responsePlans.rows.find((r) => r.data.planId === planId)];
					selectedPlans.push(...responsePlans.rows.filter((r) => r.data.planId !== planId));
					responsePlans.rows = selectedPlans;
				}
				setAppState({
					...appState,
					plans: responsePlans,
					mainMessage: responseMainMessage,
				});
				setCurrentMessage(responseJson);
				setCurrentPrescriberMessage(prescriberMessage);
				setPlans(responsePlans);
				setMainMessage(responseMainMessage);
				setMinPlans(minPlans);
				setMaxPlans(maxPrintPlans);
				if (responsePlans?.headers) {
					//pre select the columns to the max number of visible columns defined
					const visibleColumns = [];
					const maxPrintableColumns = getConfigValue(appState.config.PlanList.MaxPrintableColumns, appState)?.Value;
					for (let index = 0; index < maxPrintableColumns && index < responsePlans.headers.length; index++) {
						visibleColumns.push(responsePlans.headers[index].key);
					}
					setSelectedColumnState(visibleColumns);
					setExceedMaxColumns(responsePlans?.headers.length > maxDisplayColumns);
				}
			})
			.catch((e) => showBoundary(e));
	}

	function getMessageCategories() {
		if (appState.products.length > 0) {
			const categories = appState.products.find((x) => {
				return x.productId === appState.currentProduct.clientProductId;
			});
			setCategories(categories.messages);
		}
	}

	function getImprovedMessage(mainMessage) {
		if (tier && planName) {
			return Mustache.render(mainMessage, { Tier: tier, PlanName: planName });
		} else {
			return mainMessage;
		}
	}

	function onProductClick(product) {
		setAppState({ ...appState, currentProduct: product });
		setCurrentProduct(product);
		setShowProductsModal(false);
		setProductImageSource(product.imagePath);
		navigate('/MessageSelectionPage');
	}

	function showTooManyOptionsSelectedMessage() {
		let availableOptions = [];

		if (isSingleOptionFormularyDrillDownEnabled) {
			availableOptions.push(getConfigValue(appState.config?.PlanList?.Options?.Formularies, appState)?.Value);
		}

		if (isSingleOptionRestrictionDetailsEnabled) {
			availableOptions.push(getConfigValue(appState.config?.PlanList?.Options?.Restrictions, appState)?.Value);
		}

		if (isSingleOptionSavingsCardEnabled) {
			availableOptions.push(getConfigValue(appState.config?.PlanList?.ConfigureOption?.SavingsCard, appState)?.Value);
		}

		if (isSingleOptionSpecialtyPharmacyEnabled) {
			availableOptions.push(getConfigValue(appState.config?.PlanList?.Options?.SpecialtyPharmacy, appState)?.Value);
		}

		const errorMessage = getConfigValue(
			appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.TooManyItemsSelected?.Error,
			appState
		)?.Value;
		const selectableOptions = availableOptions.join(' or ').toLowerCase();

		triggerToastAlert(
			getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.SelectSingleOption?.TooManyItemsSelected?.Title, appState)?.Value,
			Mustache.render(errorMessage, { selectableOptions }),
			false
		);
	}

	function closeOpenMenus(event) {
		// click outside of menus and content menus = close menus
		if (
			configMenuRef.current &&
			!configMenuRef.current.contains(event.target) &&
			!configMenuContentRef.current.contains(event.target) &&
			isConfigMenuVisible
		) {
			setIsConfigMenuVisible(false);
		}
	}

	function onDetailsClick() {
		setShowMessageDetailsModal(true);
	}

	function handleBackButton() {
		if (appState.category.messageType === MessageTypes.ImprovedAccess) {
			navigate('/WinPlanListPage');
		} else {
			navigate('/MessageSelectionPage');
		}
	}

	function navigateToPage(category) {
		const isContainerMessage = category.isContainerMessage;
		if (category.messageType === MessageTypes.ImprovedAccess) {
			navigate('/WinPlanListPage');
		} else if (isContainerMessage) {
			navigate('/ContainerMessagePage');
		} else {
			navigate('/PlanListPage');
		}
	}

	function onDetailsCategoryClick(category) {
		setPageCategory(category);
		setShowMessageDetailsModal(false);
		updateSelectMessageCategory(category);
		navigateToPage(category);
		setSelectedPlans([]);
		planId = null;
	}

	document.addEventListener('mousedown', closeOpenMenus);

	async function onPreviewClick() {
		setPdfUrl(null);
		setShowPreviewModal(true);
		const response = await loadDoc(documentGenerationType.Preview);
		const url = URL.createObjectURL(response.blob);
		setPdfFileName(response.fileName);
		setPdfUrl(url);
	}

	async function loadDoc(generationType) {
		setIsLoading(true);

		const props = {
			productId: currentProduct.clientProductId,
			prescriberId: prescriberId,
			messageType: pageCategory.messageCode,
			selectedPlans: selectedPlans.map((plan) => ({
				planId: plan.planId,
				includeRestrictions: plan.includeRestrictions,
				includeFormularies: plan.includeFormularies,
				includeSpecialtyPharmacy: plan.includeSpecialtyPharmacy,
			})),
			printPrescriberName: configHCPisChecked,
			addEHRBackPage: configEHRisChecked,
			marketid: Number(marketId),
			territoryid: territoryId,
			generateFor: generationType,
			columnKeys: selectedColumnState,
			messageTypeId: pageCategory.messageTypeId,
			improvedAccessPlan: planId,
			// the config option is to turn off the location name, the API requirement is for it to be shown
			includeLocationName: !configLocationNameIsChecked,
			includeSavingsCard: savingsCardIsChecked,
			addCoverPage: printOptionsCoverPageIsChecked,
			targetLevel: prescriberType,
		};
		const response = await documentGenerate(props)
			.then((responseJson) => {
				setIsLoading(false);

				// store the properties of the last document generated
				setAppState({
					...appState,
					lastGeneratedDocument: props,
					sellSheetId: responseJson.sellSheetId,
				});

				return responseJson;
			})
			.catch((err) => {
				setShowPreviewModal(false);
				setIsLoading(false);
				triggerToastAlert('Error', 'There was an error generating the document. Please try again.', true);
				console.error(err);
			});
		return response;
	}

	async function onGenerateClick() {
		const response = await loadDoc(documentGenerationType.Generate);
		const url = URL.createObjectURL(response.blob);
		navigate('/GenerateDocumentPage', {
			state: { pdfURL: url, fileName: response.fileName },
		});
	}

	function updateSelectedPlans(_, selectedPlans) {
		setSelectedPlans(selectedPlans);
	}

	return (
		<>
			<div>
				<div className='title-bar'>
					<div className='container-xl'>
						<div className='row'>
							<div className='col-12'>
								<h1 className='type-24 mt-4 mb-4'>{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
								<ul className='wizard-list hide-noncurrent-mobile horizontal mb-5'>
									<li className='wizard-step state-complete'>
										<span className='wizard-step-icon'></span>
										<span className='wizard-step-label'>
											{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}
										</span>
									</li>
									<li className='wizard-step state-complete'>
										<span className='wizard-step-icon'></span>
										<span className='wizard-step-label'>
											{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}
										</span>
									</li>
									<li className='wizard-step state-current'>
										<span className='wizard-step-icon'></span>
										<span className='wizard-step-label'>
											{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}
										</span>
									</li>
									<li className='wizard-step state-future'>
										<span className='wizard-step-icon'></span>
										<span className='wizard-step-label'>
											{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className='container-fluid pt-3 pt-md-4 pb-1 color-bg-bodybg z-2' id='plan-list-header' onScroll={planListHeaderSticky()}>
					<div className='container-xl'>
						<div className='row d-flex align-items-md-center mb-2 mb-lg-4'>
							<div className='col-12 col-md-6 order-2 order-md-1'>
								<h1 className='type-20 color-grey-d1 mt-4 mt-md-0 hide-sticky'>
									{getConfigValue(appState.config?.PlanList?.Title, appState)?.Value}
								</h1>
								<div className='row justify-content-end show-sticky-only'>
									<div className='row justify-content-start'>
										<div className='col-6 col-md-auto type-14 text-md-start'>
											<div className='color-grey-d1'>{getConfigValue(appState.config?.PlanList?.ProductLabel, appState)?.Value}</div>
											{appState.currentProduct.name}
										</div>
										<div className='col-6 col-md-auto type-14 text-md-start'>
											<div className='color-grey-d1'>{getConfigValue(appState.config?.PlanList?.MessageTypeLabel, appState)?.Value}</div>
											{pageCategory.category}
										</div>
									</div>
								</div>
							</div>
							<div className='col-12 col-md-6 order-1 order-md-2'>
								<div className='row justify-content-end'>
									<div className='col-6 col-md-auto type-14 text-md-end'>
										<div className='color-grey-d1'>
											{prescriberType === hcpType.Prescriber
												? getConfigValue(appState.config?.PlanList?.ProviderLabel, appState)?.Value
												: getConfigValue(appState.config?.PlanList?.GroupPracticeLabel, appState)?.Value}
										</div>
										{getName(appState)}
									</div>
									<div className='col-6 col-md-auto type-14 text-md-end'>
										<div className='color-grey-d1'>{getConfigValue(appState.config?.PlanList?.TerritoryLabel, appState)?.Value}</div>
										{appState.territory?.name}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='my-8 d-none' id='plan-list-header-spacer'></div>

				{/* <!-- Dropdowns row --> */}

				<div className='container-xl'>
					<div className='row d-flex mb-5'>
						{/* This section will be replaced with the config data to pull in the brands */}
						<div className='col-12 col-md-4'>
							<ProductsModalButton
								setShowProductsModal={setShowProductsModal}
								productImageSource={productImageSource}
								productName={currentProduct?.productName}
							></ProductsModalButton>
						</div>

						<div className='col-12 col-md-4 mt-4 mt-md-0'>
							<CategoryDropdown pageCategory={pageCategory} onDetailsClick={onDetailsClick} isLoading={isLoading} />
						</div>

						<div className='col-12 col-md-4 mt-4 mt-md-0'>
							<PrintOptionDropdown
								isConfigMenuVisible={isConfigMenuVisible}
								setIsConfigMenuVisible={setIsConfigMenuVisible}
								configMenuContentRef={configMenuContentRef}
								configMenuRef={configMenuRef}
								configHCPisChecked={configHCPisChecked}
								setConfigHCPisChecked={setConfigHCPisChecked}
								configEHRisChecked={configEHRisChecked}
								setConfigEHRisChecked={setConfigEHRisChecked}
								isLoading={isLoading}
								selectedColumnState={selectedColumnState}
								setSelectedColumnState={setSelectedColumnState}
								printConfigColumnHeaders={plans.headers}
								isDisabled={false}
								printOptionsEhrBackPageEnabled={printOptionsEhrBackPageEnabled}
								printOptionsCoverPageEnabled={printOptionsCoverPageEnabled}
								configCoverisChecked={printOptionsCoverPageIsChecked}
								setConfigCoverisChecked={setPrintOptionsCoverPageIsChecked}
								setConfigLocationNameIsChecked={setConfigLocationNameIsChecked}
								configLocationNameIsChecked={configLocationNameIsChecked}
								savingsCardIsChecked={savingsCardIsChecked}
								setSavingsCardIschecked={setSavingsCardIsChecked}
								savingsCardIsEnabled={savingsCardIsEnabled}
								canSelectSavingsCard={selectableOptions.canSelectSavingsCard}
								onOptionsClick={(data) => {return executePrintOptionRestrictions(data, parentState, setSelectableOptions, showTooManyOptionsSelectedMessage)}}
							/>
						</div>
					</div>

					{/* <!-- Heading row --> */}
					<div className='row my-5 mb-lg-3'>
						<div className='col-12'>
							<h1
								className='type-24 type-bold text-primary ag_message_title py-3'
								dangerouslySetInnerHTML={{
									__html: getImprovedMessage(mainMessage),
								}}
							></h1>
						</div>
					</div>
					{currentMessage && (
						<PlanListTable
							updateSelectedPlans={updateSelectedPlans}
							message={currentMessage}
							parentState={parentState}
							setSelectableOptions={setSelectableOptions}
							triggerToastAlert={triggerToastAlert}
							executePrintOptionRestrictions={executePrintOptionRestrictions}
							showTooManyOptionsSelectedMessage={showTooManyOptionsSelectedMessage}
						></PlanListTable>
					)}
				</div>
			</div>

			<div className='button-bottom-container-sticky'>
				<div className='container-xl'>
					<div className='row justify-content-center justify-content-lg-between py-3'>
						<div className='col-10 col-sm-8 col-md-6 col-xl-5'>
							<div className='d-flex flex-column flex-lg-row'>
								<button
									onClick={onPreviewClick}
									disabled={!isValid || isLoading}
									className={`btn btn-outline-primary btn-lg flex-fill w-100 ${isValid || isLoading ? '' : 'disabled'}`}
								>
									{getConfigValue(appState.config?.PlanList?.Preview.Label, appState)?.Value || null}
								</button>
								<div className='flex-fill w-100 ms-lg-3 pe-3'></div>
							</div>
							<hr className='my-5 d-lg-none' />
						</div>
						<div className='w-100 d-lg-none'></div>
						<div className='col-10 col-sm-8 col-md-6 col-xl-5 justify-content-end'>
							<div className='d-flex flex-column flex-lg-row-reverse'>
								<button
									className={`btn btn-success btn-lg mb-3 mb-lg-0 ms-lg-3 flex-fill w-100 ${isValid || isLoading ? '' : 'disabled'} ${
										isLoading ? 'btn-working' : ''
									}`}
									type='button'
									disabled={!isValid || isLoading}
									onClick={onGenerateClick}
								>
									{' '}
									{getConfigValue(appState.config?.PlanList?.Generate?.Label, appState)?.Value || null}
								</button>
								<button type='button' onClick={handleBackButton} className='btn btn-secondary btn-lg flex-fill w-100'>
									{getConfigValue(appState.config?.Common?.Back, appState)?.Value || null}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ToastAlert show={showToast} setShow={setShowToast} title={toastTitle} message={toastMessage} autohide={toastAutohide} />

			<MessageDetailsModal
				show={showMessageDetailsModal}
				setShow={setShowMessageDetailsModal}
				categories={categories}
				onDetailsCategoryClick={onDetailsCategoryClick}
			/>

			<PreviewModal
				show={showPreviewModal}
				setShow={setShowPreviewModal}
				onGenerateClick={onGenerateClick}
				isValid={isValid}
				isLoading={isLoading}
				pdfUrl={pdfUrl}
				showGenerateBack={true}
				showPrintDownload={false}
				pdfFileName={pdfFileName}
				supportsProfessionalPrinting={true}
				skipPreviewTrack={true}
			/>

			<ProductsModal show={showProductsModal} setShow={setShowProductsModal} onProductClick={onProductClick} />
		</>
	);
}

export default PlanListPage;
